import React from "react";
import { useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import "./PlanDetails.css";

export default function Details({ getPlanServicesDetail }) {
  const getSelectedPlan = useSelector((state) => state.plan.getSelectedPlan);

  var cityId = parseInt(storage.getStorage("cityId"));

  const terms = [
    {
      heading:
        "Any plan can be cancelled within 7 days subject to a cancellation fee. The plan can only be cancelled if none of the service has been utilised by the customer.",
    },
    {
      heading:
        `The plan is valid for family members ( ${getSelectedPlan?.data?.data?.data?.data?.relationsAllowed || "-"}).`,
    },
    {
      heading:
        "OPD consultation means treatment and examination from a network clinic and hospital. which do not require hospitalisation.",
    },
    {
      heading:
        "To avail of any of the services a valid Id needs to be provided to the healthcare partner and services provider.",
    },
    {
      heading: "Any impersonation can lead to the cancellation of the plan.",
    },
    {
      heading:
        "It is advisable to take an OPD consultation appointment a minimum of 1 day prior to the date of consultation. The appointment will schedule based on the availability of a consulting Doctor. ",
    },
    {
      heading: "Talk to the doctor - will be available from 9 am to 7 pm. ",
    },
    {
      heading:
        "Health risk assessment is a tool to assess current medical conditions and it is not a substitute for doctor consultation. ",
    },
    {
      heading:
        "Health checkup booking will only be scheduled 24 hours before the booking time. ",
    },
  ];



  return (
    <div>
      <div className="PlanTermsAndConditionsContainer">
      {(cityId && cityId !== -1 &&
          getSelectedPlan.data?.data.data.data.cityIds.includes(cityId)) ? (
          ""
        ) : (
          <div style={{color:"#f7364a"}}>This plan is not currently available in your city, However, you may still proceed with the purchase.</div>
        )}
        <div className="PlanTermsAndConditionsHeading">Details</div>
        <ul className="PlanTermsAndConditionsContainerList">
          {getPlanServicesDetail?.data?.data?.data?.data.map((item, index) => (
            <li className="PlanTermsAndConditionsSubHeading">{item}</li>
          ))}
        </ul>
        <div>
          <div className="PlanTermsAndConditionsHeading">Terms & Condition</div>
          {terms.map((item, index) => (
            <div className="PlanTermsAndConditionsContainerList" key={index}>
              <div className="PlanTermsAndConditionsSubHeading">
                {item.heading}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
