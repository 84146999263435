import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { getPopularPlanRequest } from "../../../redux/Action/Landing/getPopularPlanAction";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SubscriptionHero from "../../Organisms/Hero/SubscriptionHero";
import RequestCallback from "../../Organisms/Subscription/RequestCallback";
import SubscriptionBody from "../../Organisms/Subscription/SubscriptionBody";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestions/FrequentlyAskedQuestion";
import storage from "../../../Utils/storage";


function SubscriptionPage() {
  const dispatch = useDispatch();
  var cityId = parseInt(storage.getStorage("cityId"));


  const data = [
    {
      question: "What is Yuva Health Plans?",
      answer:
        "Yuva Health is a health  care membership  Plan for your full family. It offers OPD doctor consultations (Inclinic ), Full body health checkup  and chat with doctor along with other services on Yuva Health platform for 12 whole months for your full family of 4 members.",
    },
    {
      question: "What is an OPD doctor consultation?",
      answer:
        "An OPD (Outpatient Department) doctor consultation is a visit to a doctor's clinic for the treatment of an illness or a medical condition that does not require an overnight stay at a hospital. ",
    },
    {
      question: "What is an Inclinic consultation? ",
      answer:
        "An Inclinic consultation is a type of OPD consultation where you can visit the doctor at their clinic or hospital for a face-to-face consultation.",
    },
    {
      question: "What is a full-body health checkup? ",
      answer:
        "A full-body health checkup is a comprehensive medical examination that includes tests and screenings to assess the overall health of an individual. It typically includes tests for blood pressure, cholesterol, blood sugar, liver function, kidney function, and other important parameters. ",
    },
    {
      question:
        "How can I book an appointment for an OPD consultation or a full-body health checkup? ",
      answer:
        "You can book an appointment by visiting the Yuva Health website and selecting the service you require. ",
    },
    {
      question: "Can I consult with a doctor online?",
      answer:
        "Yes, you can chat with a doctor online through the Yuva Health website through our Talk to Doctor Service. This is a convenient way to get medical advice and treatment from the comfort of your own home.",
    },
    {
      question: "How can I access my test reports? ",
      answer:
        "You can access your test reports by logging into your account on the Yuva Health website. Your reports will be available for download once they are ready",
    },
    {
      question: "What if I have more questions about my health or treatment?",
      answer:
        "If you have more questions or concerns about your health or treatment, you can always speak to our health advisor. They will be able to provide you with the necessary guidance and advice.",
    },
  ];

  useEffect(() => {
    dispatch(getPopularPlanRequest({cityId:cityId}));
  }, [dispatch]);

  return (
    <>
      <Title title="YUVA |  Comprehensive packages at a fraction of the cost." />
      <Helmet>
        <title>Yuva Health: Subscribe Today</title>
        <meta name="description" content="Subscribe today to Yuva Health and take control of your well-being with our subscription plans. Access comprehensive health services, exclusive benefits, and priority appointments.
         Join us in prioritizing your health journey with convenient and affordable healthcare solutions." />
      </Helmet>
      <ScrollToTop />
      <SubscriptionHero />
      <SubscriptionBody />  
      <RequestCallback />
      <FrequentlyAskedQuestion data={data} />
    </>
  );
}

export default SubscriptionPage;
