import RequestService from "../../RequestService";
import { api } from "../../../helpers/Advertisement/AdvertisementConstants";

class NetworkService {
  getCall(action) {
    const endPoint = `${api.getCall}/web?forWeb=true`;   
      return RequestService.postRequest(endPoint, {
        number: action.data.mobileNumber,
        city: action.data.city,
        name: action.data.Name,
        time: action.data.dateAndTime,
        doctorName: action.data.doctorname,
        doctorSpeciality: action.data.doctorSpeciality,
        hospitalName: action.data.hospitalName,
        state: action.data.state,
      })
    
    
  }
}
export default new NetworkService();
